(function($, window, document, undefined) { // eslint-disable-line no-unused-vars
  'use strict';

  const Misc = {

    sliderScrollDown() {
      const slider = $('.c-block--type-slider'),
        arrow = slider.find('.c-block__slider-scrolldown');

      if( !arrow.length ) {
        return;
      }

      arrow.on('click', () => {
        const sliderheight = slider.outerHeight();

        $('html, body').animate({
          'scrollTop': sliderheight
        }, 500); // eslint-disable-line no-magic-numbers
      });
    },

    pageTitle() {
      const secondaryMenu = $('.c-secondary-nav');

      if( secondaryMenu.length ) {
        const title = $('.c-page__title');

        if( title.length ) {
          title.parent().hide();
        }
      }
    },

    spaceAnimation() {
      const spaceContainer = $('.space-animation .c-block__slider-slide');

      if( !spaceContainer.length ) {
        return;
      }

      const animationContainer = $('<canvas></canvas>', {
        class: 'c-space__animation',
        css: {
          'display': 'block',
          'position': 'absolute',
          'top': 0,
          'bottom': 0,
          'left' : 0,
          'right': 0,
          'z-index': 1,
        }
      });

      spaceContainer.append(animationContainer);

      Particles.init({ // eslint-disable-line no-undef
        selector: '.c-space__animation',
        color: '#ffffff',
        maxParticles: 100,
        connectParticles: true,
        responsive: [
          {
            breakpoint: 1024,
            options: {
              maxParticles: 80
            }
          },
          {
            breakpoint: 768,
            options: {
              maxParticles: 60
            }
          }, {
            breakpoint: 375,
            options: {
              maxParticles: 50
            }
          }
        ]
      });
    },

    headingsFont() {
      const elements = $('.c-menu__items, .c-btn, .c-secondary-nav, .c-side-nav, .cform-container');

      elements.addClass('font-headings');
    },

    init() {
      $(document).ready( () => {
        //this.pageTitle();
        this.headingsFont();
      });

      $(window).on('load', () => {
        this.sliderScrollDown();
        this.spaceAnimation();
      });

      $(window).on('resize', () => {
      });
    }

  }

  module.exports = Misc;

})(jQuery, window, document);
